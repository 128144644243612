import { ApolloError, useApolloClient } from '@apollo/client';
import cookie from 'cookie';
import { useCurrentUserQuery, useLogoutUserMutation, User, UserType } from 'generated/graphql';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { isClientSide } from 'utils/isClientSide';
import { removeAuthCookies, removeAuthLocalStorage } from '../lib/auth';
import { useRouter } from 'next/navigation';

type MeContextType = {
  me: User | null;
  logout: () => void;
  error?: ApolloError;
  refetch: () => void;
  loading: boolean;
  isLoggedIn: boolean;
  adminUserType: UserType | null;
  setAdminUserType: (userType: UserType | null) => void;
};

const MeContext = React.createContext<MeContextType | null>(null);

export const MeProvider = ({ children }) => {
  const { data, loading, error, refetch } = useCurrentUserQuery({ notifyOnNetworkStatusChange: true });
  const [adminUserType, setAdminUserType] = useState<UserType | null>(null);
  const client = useApolloClient();
  const router = useRouter();

  const logout = useCallback(() => {
    removeAuthCookies();
    removeAuthLocalStorage();
    client.cache.reset();
    sessionStorage.removeItem('chat');

    router.replace('/');
  }, [client.cache]);

  let isLoggedIn = Boolean(data?.me) && Boolean(data?.me?.isRegistered);

  if (adminUserType) {
    isLoggedIn = adminUserType === UserType.Registered;
  }

  const ctxValue = useMemo(
    () => ({ logout, me: data?.me as User, isLoggedIn, loading, adminUserType, setAdminUserType, error, refetch }),
    [data?.me, isLoggedIn, logout, loading, adminUserType, setAdminUserType, error, refetch]
  );

  return <MeContext.Provider value={ctxValue}>{children}</MeContext.Provider>;
};

export const useMe = () => {
  const [logOutBackend] = useLogoutUserMutation();

  const context = useContext(MeContext);

  if (!context) {
    throw new Error('useMe must be used within a MeProvider');
  }

  let refreshToken: string | null = null;

  if (isClientSide()) {
    // First try to get the refresh token from the cookie, then from localStorage.
    refreshToken = cookie.parse(document.cookie).refreshToken || localStorage.getItem('refreshToken');
  }

  const logout = useCallback(async () => {
    if (!refreshToken) {
      return;
    }

    logOutBackend({
      variables: {
        refreshToken,
      },
    });

    context.logout();
  }, [context, logOutBackend, refreshToken]);

  return { ...context, logout };
};
